<template>
  <div class="footer-container center column">
    <div class="footer">
    <div class="footer-title-container">
      <p class="footer-title">&nbsp;한선생직업전문학교&nbsp;</p>
      <span class="footer-title">
      <p>&nbsp;대표번호 : 031.255.8586 / 031.268.5252&nbsp;</p><hr class="v-hr">
      <p>&nbsp;FAX : 031.207.5200&nbsp;</p>
      </span>
    </div>
    <div class="footer-content-container">
      <span class="footer-content">
        <p>&nbsp;상호명 : 한선생직업전문학교&nbsp;</p><hr class="v-hr">
        <p>&nbsp;주소 : 경기도 수원시 팔달구 정조로 867&nbsp;</p><hr class="v-hr">
        <p>&nbsp;사업자등록번호 : 135-90-77053&nbsp;</p><hr class="v-hr">
        <p>&nbsp;대표자명 : 나순흠&nbsp;</p><hr class="v-hr">
        <p>&nbsp;통신판매업 신고번호 : 2012-경기수원-0246&nbsp;</p><hr class="v-hr">
        <p>&nbsp;Copyright©educook.kr All Rights Reserved&nbsp;</p>
      </span>
    </div>
    </div>
  </div>
</template>